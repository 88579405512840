@font-face {
    font-family: dancing;
    src: url("../assets/fonts/Dancing_Script/DancingScript-VariableFont_wght.ttf");
    font-display: swap;
}
@font-face {
    font-family: robotoMedium;
    src: url("../assets/fonts/Roboto/Roboto-Medium.ttf");
    font-display: swap;
}

$primary-color: lightblue;
$secondary-color: lightgrey;

$borderRed: 1px solid red;
