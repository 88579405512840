.contactContainer {
    width: 100%;
    height: 100vh;
    background: url("../../assets/img/background-3228704_960_720.webp");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: relative;
    display: flex;
    justify-content: space-between;
    img {
        width: 50px;
        height: 50px;
        margin: 20px 50px;
        cursor: pointer;
        transition: transform 0.3s linear;
        &:hover {
            transform: scale(1.1);
        }
        @media screen and (max-width: 980px) {
            width: 30px;
            height: 30px;
            margin: 5px 10px;
        }
    }
    .headBandContainer {
        width: 200px;
        height: 200px;
        // position: relative;
        // left: 100%;
        // background-color: transparent;
        // transform: translate(-100%);
        // overflow: hidden;
        // background-color: #eeebda;
        // img {
        //     position: absolute;
        //     // top: -100px;
        //     // right: -100px;
        //     width: 100%;
        // }
    }
    .contactModal {
        width: 70%;
        height: 50%;
        background-color: rgba(#eeebda, 0.6);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 10px;
        display: flex;
        justify-content: space-around;
        @media screen and (max-width: 980px) {
            width: 100%;
            height: 90%;
            flex-direction: column;
            background-color: rgba(#eeebda, 0.7);
        }
        .informations {
            width: 45%;
            height: 100%;
            text-align: right;
            padding-right: 20px;
            @media screen and (max-width: 980px) {
                width: 100%;
                text-align: center;
                padding-right: 0;
            }
            .title {
                font-size: 48px;
                margin-top: 20px;
                margin-bottom: 60px;
                font-weight: bold;
                color: #5f3f32;
                @media screen and (max-width: 980px) {
                    text-decoration: underline;
                }
            }
            p {
                font-size: 24px;
                margin-bottom: 20px;
            }
            .adress {
                @media screen and (max-width: 980px) {
                    display: flex;
                    flex-direction: column;
                }
                span {
                    @media screen and (max-width: 980px) {
                        margin-top: 10px;
                    }
                }
                span:nth-child(1) {
                    @media (max-width: 980px) {
                        display: none;
                    }
                }
            }
            .dispo {
                @media screen and (max-width: 980px) {
                    height: 11vh;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                }
            }
            .socialTitle {
                font-size: 24px;
                font-weight: bold;
                color: #5f3f32;
                @media screen and (max-width: 980px) {
                    position: absolute;
                    bottom: 1vh;
                    left: 50%;
                    transform: translate(-50%);
                }
            }
            .social {
                display: flex;
                margin-top: 20px;
                justify-content: flex-end;
                @media screen and (max-width: 980px) {
                    position: absolute;
                    bottom: 0;
                    right: 2vw;
                }
                a {
                    img {
                        width: 3vw;
                        transition: transform 0.4s linear;
                        margin: 0px 0px 0px 10px;
                        @media screen and (max-width: 980px) {
                            width: 8vw;
                            height: 4vh;
                        }
                        &:hover {
                            transform: scale(1.06);
                        }
                    }
                }
            }
        }
        .separation {
            height: 90%;
            width: 5px;
            background-color: #5f3f32;
            margin: auto 0;
            @media screen and (max-width: 980px) {
                display: none;
            }
        }
        .mail {
            width: 45%;
            height: 100%;
            position: relative;
            @media screen and (max-width: 980px) {
                width: 100%;
                margin-top: -15vh;
            }
            button {
                background-color: rgba(#5f3f32, 0.5);
                border: 2px solid #fcf9f2;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: scale(1) translate(-50%, -50%);
                width: 80%;
                height: 15%;
                border-radius: 25px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 18px;
                text-decoration: none;
                color: #fcf9f2;
                transition: transform 0.4s linear;
                cursor: pointer;
                &:hover {
                    transform: scale(1.02) translate(-50%, -50%);
                    box-shadow: inset 0px 0px 10px #fcf9f2;
                }
            }
        }
    }
}
