.gallery {
    color: white;
    width: 90%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background-color: rgba(94, 30, 85, 0.24);
    border-radius: 10px;
    @media screen and (max-width: 1080px) {
        height: 90%;
    }
}
.arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 80px;
    background-color: rgba(139, 139, 139, 0.308);
    border-radius: 80px;
    border: 1px solid lightgray;
    cursor: pointer;
    transition: transform 0.4s ease-in-out;
    position: absolute;
    @media screen and (max-width: 1080px) {
        flex-direction: column;
        position: absolute;
        width: 25px;
        height: 25px;
    }
    &:hover {
        transform: scale(1.06);
    }
    &__left {
        left: -40px;
        @media screen and (max-width: 1080px) {
            left: -12px;
        }
        img {
            width: 100%;
            height: 50px;
        }
    }
    &__right {
        right: -40px;
        @media screen and (max-width: 1080px) {
            right: -12px;
        }
        img {
            width: 100%;
            height: 50px;
            transform: rotate(180deg);
        }
    }
}
.projectContainer {
    height: 80%;
    width: 80%;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    border-radius: 10px;
    @media screen and (max-width: 1080px) {
        flex-direction: column;
        width: 100%;
        height: 100%;
    }
    .imgContainer {
        width: 48%;
        height: 100%;
        overflow: hidden;
        border-radius: 10px;
        @media screen and (max-width: 1080px) {
            width: 100%;
            margin-bottom: 30px;
        }

        img {
            width: 100%;
            // object-fit: contain;
        }

        .in {
            width: 100%;
            height: 100%;
            animation: in 0.3s ease-out;
        }
        .out {
            width: 100%;
            height: 100%;

            animation: out 0.3s ease-out;
        }
    }
    .descriptionContainer {
        background-color: transparent;
        width: 48%;
        height: 100%;
        overflow: hidden;
        background-color: rgba($color: lightgray, $alpha: 0.2);
        position: relative;
        border-radius: 10px;
        @media screen and (max-width: 1080px) {
            width: 100%;
        }
        .descriptionAndTechnosContainer {
            display: flex;
            flex-direction: column;
            height: 100%;
        }

        &__title {
            font-size: 60px;
            display: flex;
            justify-content: center;
            align-items: center;

            width: 100%;
            height: 20%;
            background-color: rgba($color: lightgray, $alpha: 0.2);
            border-bottom: 1px solid rgba($color: white, $alpha: 0.5);
            @media screen and (max-width: 1080px) {
                font-size: 40px;
            }
        }
        &__description {
            font-size: 36px;
            text-align: left;
            width: 100%;
            padding: 15px;
            height: 30%;
            @media screen and (max-width: 1080px) {
                font-size: 23px;
            }
        }
        &__technos {
            padding-left: 20px;
            font-size: 32px;
            height: 50%;
            display: flex;
            flex-direction: column;

            @media screen and (max-width: 1080px) {
                font-size: 18px;
            }

            h3 {
                font-size: 36px;
                text-decoration: underline;
                margin-bottom: 20px;
                @media screen and (max-width: 1080px) {
                    font-size: 32px;
                }
            }
        }
        &__link {
            font-size: 24px;
            text-align: center;
            bottom: 10px;
            position: absolute;
            width: max-content;
            left: 50%;
            transform: translate(-50%);

            a {
                cursor: pointer;
                color: white;
                text-decoration: none;
                -webkit-transition: color 0.5s ease-out;
                -moz-transition: color 0.5s ease-out;
                -o-transition: color 0.5s ease-out;
                transition: color 0.4s ease-out;
                text-decoration: none;
                color: white;
                &:visited {
                    color: white;
                }
                &:hover {
                    color: rgb(182, 16, 233);
                }
                &::after {
                    content: "";
                    height: 1px;
                    width: 100%;
                    background-color: rgb(182, 16, 233);
                    position: absolute;
                    left: 0;
                    top: 120%;
                    transform: scale(0);
                    transition: transform 0.4s ease-out;
                    transform-origin: center;
                }
                &:hover {
                    &::after {
                        transform: scale(1);
                        transform-origin: center;
                        transition: transform 0.4s ease-out;
                    }
                }
            }
        }
        .notVisible {
            display: none;
        }
        .right {
            animation: right 0.3s 0.4s ease-out;
        }
        .left {
            width: 100%;
            height: 100%;
            animation: left 0.3s ease-out;
        }
    }
}
@keyframes in {
    0% {
        transform: scale(0.7);
    }

    100% {
        transform: scale(1);
    }
}
@keyframes out {
    0% {
        transform: scale(0.7);
    }

    100% {
        transform: scale(1);
    }
}
@keyframes left {
    0% {
        transform: translateY(250px);
    }

    100% {
        transform: translateY(0);
    }
}
