@import "../../utils/variables.scss";
.headerContainer {
    color: rgb(255, 255, 255);
    width: 100%;
    height: 12vh;
    background: rgba($color: #ececec, $alpha: 0.1);
    border-top: 3px solid rgba($color: #ececec, $alpha: 0.3);
    border-bottom: 3px solid rgba($color: #ececec, $alpha: 0.3);
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: roboto;
    position: absolute;
    z-index: 100;
    .logoAndPhoto {
        position: relative;
        margin-left: 5vw;
        width: 100px;
        max-height: 100%;
        height: 100px;
        overflow: hidden;
        border-radius: 50%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media screen and (max-width: 600px) {
            width: 60px;
            height: 60px;
        }
        &:hover .logo {
            transform: translateY(-120px);
        }
        &:hover .photo {
            transform: translateX(0px);
        }
    }
    .photo {
        position: absolute;
        transform: translateX(120px);
        object-position: center;
        width: 100%;
        border-radius: 50%;
        transition: transform 0.3s 0.1s linear;
    }
    .logo {
        position: absolute;
        font-size: 48px;
        font-family: dancing;
        transform: translateY(0);
        transition: transform 0.3s 0.1s linear;
        text-align: center;
        width: 100px;
        height: 100px;
        background: url("../../assets/img/logo.png");
        @media screen and (max-width: 750px) {
            font-size: 32px;
            width: 50px;
            height: 50px;
            background: url("../../assets/img/logoMobile.png");
        }
        div {
            font-size: 8px;
        }
    }
    .navigation {
        margin-right: 5vw;
        @media screen and (max-width: 750px) {
            width: 50%;
        }

        ul {
            display: flex;
            justify-content: space-around;

            li {
                width: max-content;
                height: max-content;
            }

            a {
                margin-left: 8vw;
                font-size: 24px;
                cursor: pointer;
                position: relative;
                -webkit-transition: color 0.5s ease-out;
                -moz-transition: color 0.5s ease-out;
                -o-transition: color 0.5s ease-out;
                transition: color 1s ease-out;
                text-decoration: none;
                color: white;
                @media screen and (max-width: 750px) {
                    font-size: 12px;
                    margin-left: 2vw;
                }
                &:visited {
                    color: white;
                }
                &:hover {
                    color: orange;
                    @media screen and (max-width: 750px) {
                        font-size: 12px;
                        margin-left: 2vw;
                    }
                }
                &::after {
                    content: "";
                    height: 1px;
                    width: 100%;
                    background-color: orange;
                    position: absolute;
                    left: 0;
                    top: 120%;
                    transform: scale(0);
                    transition: transform 0.8s ease-out;
                    transform-origin: left;
                }
                &:hover {
                    &::after {
                        transform: scale(1);
                        transition: transform 0.4s ease-out;
                        @media screen and (max-width: 750px) {
                            transform: scale(0);
                        }
                    }
                }
                #desktop {
                    @media screen and (max-width: 850px) {
                        display: none;
                    }
                }
            }
        }
    }
}
.mobile {
    color: white;
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    padding-bottom: 10px;

    @media screen and (max-width: 850px) {
        display: flex;
    }
    div {
        width: 100%;
        text-align: center;
    }
    .img {
        &__mobile {
            width: 20px;
            height: 20px;
            margin-bottom: 5px;
        }
    }
}
