@import "../../utils/variables.scss";
.mainHomeContainer {
    width: 100%;
    min-height: 100vh;
    font-family: dancing;
    scroll-behavior: smooth;

    .backgroundContainer {
        background-image: url("../../assets/img/glossaire-du-web-2_1.webp");
        width: 100%;
        height: 100vh;
        object-fit: cover;
        background-repeat: no-repeat;
        background-size: 120% 120%;
        background-position: center;
        z-index: -100;
    }
    .titleContainer {
        color: white;
        z-index: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        width: 100%;
        h1 {
            font-size: 48px;
            margin-bottom: 20px;
            @media screen and (max-width: 850px) {
                margin-bottom: 13vh;
            }
        }
        p {
            font-size: 64px;
        }
    }
}
.projectsContainer {
    width: 100%;
    height: 100vh;
    z-index: 100;
    position: relative;

    .arrowUp {
        position: absolute;
        bottom: 0;
        right: 0vw;
        overflow: hidden;
        text-align: center;

        img {
            transform: rotate(-90deg);
            width: 150px;
            height: 100px;
            margin-bottom: -20px;
            @media screen and (max-width: 850px) {
                width: 50px;
                height: 40px;
                margin-bottom: -10px;
            }
        }
        p {
            color: white;
            padding-bottom: 20px;
            @media screen and (max-width: 850px) {
                padding-bottom: 5px;
            }
        }
    }
}
.backgroundProjectsContainer {
    width: 100%;
    height: 100vh;
    background-image: url("../../assets/img/projectsContainer.webp");
    background-size: 100% 100%;
    z-index: -100;

    .projectsTitleContainer {
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding-top: 50px;
        @media screen and (max-width: 1080px) {
            padding-top: 5px;
        }
    }
    h2 {
        color: white;
        font-size: 64px;
        cursor: pointer;
        z-index: 0;
        @media screen and (max-width: 800px) {
            font-size: 30px;
        }
    }
    .line {
        width: 32vw;
        height: 1px;
        background-color: white;
        z-index: 50;
    }
    .projects {
        width: 100%;
        height: 70vh;
        margin-top: 10vh;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        @media screen and (max-width: 1080px) {
            margin-top: 0;
            height: 95%;
        }
    }
}
.filter {
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(rgba(black, 0.4), rgba(black, 0.1));
}
